<template>
  <b-overlay :show="loading" rounded="sm" variant="white" opacity="0.9">
    <b-card no-body>
      <b-card-header class="pb-50 d-flex justify-content-between">
        <b-card-title class="mb-2">Antel·lació de reserva</b-card-title>
        <!-- ICON WITH DROPDOWN -->
        <div class="d-flex">
          <b-dropdown
            variant="link"
            no-caret
            class="chart-dropdown"
            toggle-class="p-0"
            left
          >
            <template #button-content>
              <b-avatar class="mr-1" :variant="`light-${color}`" size="45">
                {{ selectedPeriod }}
              </b-avatar>
            </template>
            <b-dropdown-item
              v-for="(option, index) in periodOptions"
              :key="`period-option-${index}`"
              @click="onPeriodSelected(option.value)"
            >
              {{ option.label }}
            </b-dropdown-item>
          </b-dropdown>

          <div class="truncate">
            <h3 class="mb-25 font-weight-bolder">
              {{ cardStatistic }}
              {{ cardStatistic === 1 ? $t("dia") : $t("dies") }}
            </h3>
            <span class="font-small-3">{{ cardSubtitle }}</span>
          </div>
        </div>
      </b-card-header>

      <vue-apex-charts
        type="area"
        height="100"
        width="100%"
        :options="chartOptions"
        :series="chartData"
      />
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BCardHeader,
  BCardTitle,
  BAvatar,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import { areaChartOptions } from "./chartOptions";

export default {
  components: {
    BOverlay,
    VueApexCharts,
    BCard,
    BCardHeader,
    BAvatar,
    BDropdown,
    BCardTitle,
    BDropdownItem,
  },
  props: {
    summaryType: {
      type: String,
      default: "BOOKING",
    },
  },
  data() {
    return {
      selectedPeriod: new Date().getFullYear(),
      color: "primary",
    };
  },
  computed: {
    loading() {
      return this.$store.getters["stats/loading"];
    },
    bookingAdvanceTimeByBookingDate() {
      return this.$store.getters["stats/bookingAdvanceTimeByBookingDate"];
    },
    bookingAdvanceTimeByCheckinDate() {
      return this.$store.getters["stats/bookingAdvanceTimeByCheckinDate"];
    },
    bookings() {
      if (this.summaryType === "BOOKING") {
        // By booking date
        return this.bookingAdvanceTimeByBookingDate;
      }
      // By checkin date
      return this.bookingAdvanceTimeByCheckinDate;
    },
    cardSubtitle() {
      if (this.selectedPeriod !== "ALL") {
        return `Antel·lació de reserva ${this.selectedPeriod}`;
      }
      return "Antel·lació de reserva històrica";
    },
    cardStatistic() {
      if (!this.bookings) {
        return 0;
      }

      let advanceTime = 0;

      if (this.selectedPeriod === "ALL") {
        advanceTime = this.calculateBookingAdvanceTimeWeightedAverage();
      } else {
        advanceTime = this.calculateBookingAdvanceTimeWeightedAverageByYear(
          this.selectedPeriod
        );
      }

      return advanceTime;
    },
    chartData() {
      if (!this.bookings) {
        return [];
      }

      let data = [];

      if (this.selectedPeriod === "ALL") {
        data = this.calculateChartSeries();
      } else {
        data = [this.calculateChartSerieByYear(this.selectedPeriod)];
      }

      return data;
    },
    periodOptions() {
      const currentYear = new Date().getFullYear();
      return [
        { label: this.$t("Any pròxim"), value: currentYear + 1 },
        { label: this.$t("Any actual"), value: currentYear },
        { label: this.$t("Any passat"), value: currentYear - 1 },
        { label: this.$t("Desde l'inici"), value: "ALL" },
      ];
    },
    chartOptions() {
      const options = JSON.parse(JSON.stringify(areaChartOptions));
      options.theme.monochrome.color = $themeColors[this.color];
      return options;
    },
  },
  methods: {
    onPeriodSelected(period) {
      this.selectedPeriod = period;
    },
    calculateBookingAdvanceTimeWeightedAverageByYear(year) {
      const selectedPeriodData = this.bookings[year];

      if (!selectedPeriodData) {
        return 0;
      }

      let productsSum = 0;
      let weightsSum = 0;

      for (const month in selectedPeriodData) {
        if (selectedPeriodData[month]) {
          const monthAverage = selectedPeriodData[month].averageTime || 0;
          const monthBookingsCount =
            selectedPeriodData[month].bookingsCount || 0;
          productsSum += monthAverage * monthBookingsCount;
          weightsSum += monthBookingsCount;
        }
      }

      return Math.round(productsSum / weightsSum);
    },
    calculateBookingAdvanceTimeWeightedAverage() {
      let sum = 0;
      let count = 0;

      for (const year in this.bookings) {
        sum += this.calculateBookingAdvanceTimeWeightedAverageByYear(year);
        count += 1;
      }

      return Math.round(sum / count);
    },
    calculateChartSerieByYear(year) {
      const selectedPeriodData = this.bookings[year];

      if (!selectedPeriodData) {
        return {
          name: year,
          data: [],
        };
      }

      const series = [];

      for (const month in selectedPeriodData) {
        series.push(selectedPeriodData[month].averageTime);
      }

      return {
        name: year,
        data: series,
      };
    },
    calculateChartSeries() {
      const data = [];

      for (const year in this.bookings) {
        data.push(this.calculateChartSerieByYear(year));
      }

      return data;
    },
  },
};
</script>
