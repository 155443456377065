<template>
  <b-overlay :show="loading" rounded="sm" variant="white" opacity="0.9">
    <b-card no-body class="pb-1">
      <b-card-header class="pb-50">
        <b-card-title class="mb-2">Previsió de facturació</b-card-title>
        <!-- ICON WITH DROPDOWN -->
        <div class="d-flex">
          <b-avatar class="mr-1" :variant="`light-${color}`" size="45">
            <feather-icon size="21" icon="DollarSignIcon" />
          </b-avatar>

          <div class="truncate">
            <h3 class="mb-25 font-weight-bolder">
              {{ currentMonthBillingForecastText }}
            </h3>
            <span class="font-small-3">{{ cardSubtitle }}</span>
          </div>
        </div>
      </b-card-header>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BCardHeader,
  BCardTitle,
  BAvatar,
} from "bootstrap-vue";
import { formatCurrency } from "@/utils/formatters";

export default {
  components: {
    BOverlay,
    BCard,
    BCardHeader,
    BAvatar,
    BCardTitle,
  },
  props: {
    summaryType: {
      type: String,
      default: "BOOKING",
    },
  },
  data() {
    return {
      color: "primary",
    };
  },
  computed: {
    loading() {
      return this.$store.getters["stats/loading"];
    },
    billingSummaryByBookingDate() {
      return this.$store.getters["stats/billingSummaryByBookingDate"];
    },
    currentDate() {
      return new Date();
    },
    currentYear() {
      return this.currentDate.getFullYear();
    },
    currentMonth() {
      return this.currentDate.getMonth() + 1;
    },
    currentMonthName() {
      return this.currentDate.toLocaleString(this.$i18n.locale, {
        month: "long",
        locale: this.$i18n.locale,
      });
    },
    currentMonthBilling() {
      const billingSummary = this.billingSummaryByBookingDate;

      if (!billingSummary?.length) return null;

      const currentYearBillingSummary = billingSummary.find(
        (summaryItem) => summaryItem.year === this.currentYear
      );
      if (!currentYearBillingSummary) return null;

      const pvp = currentYearBillingSummary.months.find(
        (monthItem) => monthItem.month === this.currentMonth
      ).pvp;

      return pvp;
    },
    currentMonthBillingForecast() {
      if (!this.currentMonthBilling) {
        return 0;
      }

      const today = this.$moment();
      const dayOfMonth = today.date();
      const daysInMonth = today.daysInMonth();

      return Math.round((this.currentMonthBilling / dayOfMonth) * daysInMonth);
    },
    currentMonthBillingForecastText() {
      return formatCurrency(this.currentMonthBillingForecast);
    },
    cardSubtitle() {
      return `per el mes de ${this.currentMonthName} de ${this.currentYear}`;
    },
  },
};
</script>
