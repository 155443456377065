<template>
  <b-overlay :show="loading" rounded="sm" variant="white" opacity="0.9">
    <b-card title="Facturació">
      <!-- SUMMARY -->
      <div class="bg-light pb-2 px-2 pt-1 rounded-lg">
        <b-tabs align="left">
          <!-- MONTHLY SUMMARY -->
          <b-tab title="Mensual" active>
            <div
              class="d-block d-lg-flex justify-content-lg-between align-items-center mb-1 mb-sm-0"
            >
              <!-- LEFT -->
              <div
                v-if="currentMonthCurrentYearBillingText"
                class="text-center text-lg-left mb-2 mb-lg-0"
              >
                <h5>Facturació {{ currentMonthName }} {{ currentYear }}</h5>
                <h1 class="font-weight-bolder">
                  {{ currentMonthCurrentYearBillingText }}
                </h1>
                <b-badge
                  v-if="currentMonthBillingIncrementPercentage"
                  variant="light-secondary"
                >
                  <span> {{ currentMonthBillingIncrementPercentage }} % </span>
                  <feather-icon
                    :icon="currentMonthBillingIncrementPercentageIcon"
                    size="12"
                    :class="{
                      'text-success':
                        currentMonthBillingIncrementPercentage > 0,
                      'text-danger': currentMonthBillingIncrementPercentage < 0,
                    }"
                  />
                  <span>
                    respecte
                    {{ currentMonthName }} {{ lastYear }}
                  </span>
                </b-badge>
              </div>

              <!-- RIGHT -->
              <div class="text-center text-lg-right">
                <!-- LAST YEAR -->
                <div
                  v-if="lastYearBillingUntilCurrentMonth"
                  class="d-flex flex-column flex-xl-row justify-content-xl-end mb-50 align-items-center"
                >
                  <span class="font-weight-bold mr-25">
                    Facturació anual a {{ currentMonthName }} {{ lastYear }}:
                  </span>
                  <div class="d-flex align-items-center">
                    <span>
                      {{ lastYearBillingUntilCurrentMonthText }}
                    </span>
                    <b-badge
                      v-if="lastYearProfitUntilCurrentMonthText"
                      class="ml-50"
                    >
                      <feather-icon icon="BoldIcon" size="12" />
                      <span>
                        {{ lastYearProfitUntilCurrentMonthText }}
                        <span
                          v-if="lastYearProfitPercentageUntilCurrentMonthText"
                        >
                          ({{ lastYearProfitPercentageUntilCurrentMonthText }})
                        </span>
                      </span>
                    </b-badge>
                  </div>
                </div>

                <!-- CURRENT YEAR -->
                <div
                  v-if="currentYearBillingUntilCurrentMonth"
                  class="d-flex flex-column flex-xl-row justify-content-xl-end mb-50 align-items-center"
                >
                  <span class="font-weight-bold mr-25">
                    Facturació anual a {{ currentMonthName }} {{ currentYear }}:
                  </span>
                  <div class="d-flex align-items-center">
                    <span>
                      {{ currentYearBillingUntilCurrentMonthText }}
                    </span>
                    <b-badge
                      v-if="currentYearProfitUntilCurrentMonthText"
                      class="ml-50"
                    >
                      <feather-icon icon="BoldIcon" size="12" />
                      <span>
                        {{ currentYearProfitUntilCurrentMonthText }}
                        <span
                          v-if="
                            currentYearProfitPercentageUntilCurrentMonthText
                          "
                        >
                          ({{
                            currentYearProfitPercentageUntilCurrentMonthText
                          }})
                        </span>
                      </span>
                    </b-badge>
                  </div>
                </div>

                <!-- NEXT YEAR -->
                <div
                  v-if="nextYearBillingUntilCurrentMonth"
                  class="d-flex flex-column flex-xl-row justify-content-xl-end mb-1 align-items-center"
                >
                  <span class="font-weight-bold mr-25">
                    Facturació anual a {{ currentMonthName }} {{ nextYear }}:
                  </span>
                  <div class="d-flex align-items-center">
                    <span>
                      {{ nextYearBillingUntilCurrentMonthText }}
                    </span>
                    <b-badge
                      v-if="nextYearProfitUntilCurrentMonth"
                      class="ml-50"
                    >
                      <feather-icon icon="BoldIcon" size="12" />
                      <span>
                        {{ nextYearProfitUntilCurrentMonthText }}
                        <span
                          v-if="nextYearProfitPercentageUntilCurrentMonthText"
                        >
                          ({{ nextYearProfitPercentageUntilCurrentMonthText }})
                        </span>
                      </span>
                    </b-badge>
                  </div>
                </div>

                <!-- PROFIT PERCENTAGE -->
                <b-badge
                  v-if="currentYearProfitPercentageIncrementUntilCurrentMonth"
                  variant="light-secondary"
                >
                  <feather-icon icon="BoldIcon" size="12" />
                  <span>
                    {{ currentYearProfitPercentageIncrementUntilCurrentMonth }}
                    %
                  </span>
                  <feather-icon
                    :icon="
                      currentYearProfitPercentageIncrementUntilCurrentMonthIcon
                    "
                    size="12"
                    class="align-middle"
                    :class="{
                      'text-success':
                        currentYearProfitPercentageIncrementUntilCurrentMonth >
                        0,
                      'text-danger':
                        currentYearProfitPercentageIncrementUntilCurrentMonth <
                        0,
                    }"
                  />
                  <span> respecte el mateix període {{ lastYear }} </span>
                </b-badge>
              </div>
            </div>
          </b-tab>

          <!-- YEARLY SUMMARY -->
          <b-tab title="Anual">
            <div
              class="d-block d-lg-flex justify-content-lg-between align-items-center mb-1 mb-sm-0"
            >
              <!-- LEFT -->
              <div
                v-if="currentYearBillingText"
                class="text-center text-lg-left mb-2 mb-lg-0"
              >
                <h5>Facturació {{ currentYear }}</h5>
                <h1 class="font-weight-bolder">
                  {{ currentYearBillingText }}
                </h1>
                <b-badge
                  v-if="currentYearBillingIncrementPercentage"
                  variant="light-secondary"
                >
                  <span> {{ currentYearBillingIncrementPercentage }} % </span>
                  <feather-icon
                    :icon="currentYearBillingIncrementPercentageIcon"
                    size="12"
                    :class="{
                      'text-success': currentYearBillingIncrementPercentage > 0,
                      'text-danger': currentYearBillingIncrementPercentage < 0,
                    }"
                  />
                  <span> respecte {{ lastYear }} </span>
                </b-badge>
              </div>

              <!-- RIGHT -->
              <div class="text-center text-lg-right">
                <!-- LAST YEAR -->
                <div
                  v-if="lastYearBilling"
                  class="d-flex flex-column flex-xl-row justify-content-xl-end mb-50 align-items-center"
                >
                  <span class="font-weight-bold mr-25">
                    Facturació anual {{ lastYear }}:
                  </span>
                  <div class="d-flex align-items-center">
                    <span>
                      {{ lastYearBillingText }}
                    </span>
                    <b-badge v-if="lastYearProfitText" class="ml-50">
                      <feather-icon icon="BoldIcon" size="12" />
                      <span>
                        {{ lastYearProfitText }}
                        <span v-if="lastYearProfitPercentageText">
                          ({{ lastYearProfitPercentageText }})
                        </span>
                      </span>
                    </b-badge>
                  </div>
                </div>

                <!-- CURRENT YEAR -->
                <div
                  v-if="currentYearBilling"
                  class="d-flex flex-column flex-xl-row justify-content-xl-end mb-50 align-items-center"
                >
                  <span class="font-weight-bold mr-25">
                    Facturació anual {{ currentYear }}:
                  </span>
                  <div class="d-flex align-items-center">
                    <span>
                      {{ currentYearBillingText }}
                    </span>
                    <b-badge v-if="currentYearProfitText" class="ml-50">
                      <feather-icon icon="BoldIcon" size="12" />
                      <span>
                        {{ currentYearProfitText }}
                        <span v-if="currentYearProfitPercentageText">
                          ({{ currentYearProfitPercentageText }})
                        </span>
                      </span>
                    </b-badge>
                  </div>
                </div>

                <!-- NEXT YEAR -->
                <div
                  v-if="nextYearBilling"
                  class="d-flex flex-column flex-xl-row justify-content-xl-end mb-1 align-items-center"
                >
                  <span class="font-weight-bold mr-25">
                    Facturació anual {{ nextYear }}:
                  </span>
                  <div class="d-flex align-items-center">
                    <span>
                      {{ nextYearBillingText }}
                    </span>
                    <b-badge v-if="nextYearProfit" class="ml-50">
                      <feather-icon icon="BoldIcon" size="12" />
                      <span>
                        {{ nextYearProfitText }}
                        <span v-if="nextYearProfitPercentageText">
                          ({{ nextYearProfitPercentageText }})
                        </span>
                      </span>
                    </b-badge>
                  </div>
                </div>

                <!-- PROFIT PERCENTAGE -->
                <b-badge
                  v-if="currentYearProfitPercentageIncrement"
                  variant="light-secondary"
                >
                  <feather-icon icon="BoldIcon" size="12" />
                  <span> {{ currentYearProfitPercentageIncrement }} % </span>
                  <feather-icon
                    :icon="currentYearProfitPercentageIncrementIcon"
                    size="12"
                    class="align-middle"
                    :class="{
                      'text-success': currentYearProfitPercentageIncrement > 0,
                      'text-danger': currentYearProfitPercentageIncrement < 0,
                    }"
                  />
                  <span> respecte el mateix període {{ lastYear }} </span>
                </b-badge>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>

      <!-- CHART -->
      <e-charts
        ref="line"
        class="w-100"
        autoresize
        :options="chartOptions"
        theme="theme-color"
      />
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BBadge,
  BTabs,
  BTab,
  // BButton,
} from "bootstrap-vue";

import ECharts from "vue-echarts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/chart/line";
import theme from "./theme.json";
import { notifyError } from "@/utils/methods";
import { formatCurrency, formatDateObjectToDate } from "@/utils/formatters";

ECharts.registerTheme("theme-color", theme);

export default {
  components: {
    BOverlay,
    BCard,
    ECharts,
    BBadge,
    BTabs,
    BTab,
    // BButton,
  },
  props: {
    summaryType: {
      type: String,
      default: "BOOKING",
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    bookingsAreLoaded() {
      return this.$store.getters["stats/bookingsAreLoaded"];
    },
    billingSummaryByBookingDate() {
      return this.$store.getters["stats/billingSummaryByBookingDate"];
    },
    billingSummaryByCheckinDate() {
      return this.$store.getters["stats/billingSummaryByCheckinDate"];
    },
    currentYearSummary() {
      const year = new Date().getFullYear();
      return this.getBillingSummaryByTypeAndYear(this.summaryType, year);
    },
    nextYearSummary() {
      const year = new Date().getFullYear() + 1;
      return this.getBillingSummaryByTypeAndYear(this.summaryType, year);
    },
    lastYearSummary() {
      const year = new Date().getFullYear() - 1;
      return this.getBillingSummaryByTypeAndYear(this.summaryType, year);
    },
    twoYearsAgoSummary() {
      const year = new Date().getFullYear() - 2;
      return this.getBillingSummaryByTypeAndYear(this.summaryType, year);
    },
    chartOptionsSeries() {
      const series = [];

      if (this.twoYearsAgoSummary) {
        series.push(this.getSeriesItem(this.twoYearsAgoSummary, "#dddddd"));
      }
      if (this.lastYearSummary) {
        series.push(this.getSeriesItem(this.lastYearSummary, "#4ea7bc"));
      }
      if (this.currentYearSummary) {
        series.push(this.getSeriesItem(this.currentYearSummary, "#ffcd00"));
      }
      if (this.nextYearSummary) {
        series.push(this.getSeriesItem(this.nextYearSummary, "#eba134"));
      }

      return series;
    },
    chartOptions() {
      return {
        legend: { bottom: "0" },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: { backgroundColor: "#6a7985" },
          },
        },
        grid: {
          width: "87%",
          left: "55px",
          containLabel: false,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: [
              "Gen",
              "Feb",
              "Mar",
              "Abr",
              "Mai",
              "Jun",
              "Jul",
              "Ago",
              "Sep",
              "Oct",
              "Nov",
              "Des",
            ],
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: { show: false },
          },
        ],
        series: this.chartOptionsSeries,
      };
    },
    currentYear() {
      return new Date().getFullYear();
    },
    lastYear() {
      return this.currentYear - 1;
    },
    nextYear() {
      return this.currentYear + 1;
    },
    currentMonth() {
      return new Date().getMonth() + 1;
    },
    currentMonthName() {
      return formatDateObjectToDate(new Date(), this.$i18n.locale, {
        month: "long",
      });
    },
    currentMonthCurrentYearBilling() {
      const billingSummary =
        this.summaryType === "CHECKIN"
          ? this.billingSummaryByCheckinDate
          : this.billingSummaryByBookingDate;

      if (!billingSummary?.length) return null;

      const currentYearBillingSummary = billingSummary.find(
        (summaryItem) => summaryItem.year === this.currentYear
      );
      if (!currentYearBillingSummary) return null;

      const pvp = currentYearBillingSummary.months.find(
        (monthItem) => monthItem.month === this.currentMonth
      ).pvp;

      return pvp;
    },
    currentMonthCurrentYearBillingText() {
      return this.currentMonthCurrentYearBilling ||
        this.currentMonthCurrentYearBilling === 0
        ? formatCurrency(this.currentMonthCurrentYearBilling)
        : null;
    },
    currentMonthLastYearBilling() {
      const billingSummary =
        this.summaryType === "CHECKIN"
          ? this.billingSummaryByCheckinDate
          : this.billingSummaryByBookingDate;

      if (!billingSummary?.length) return null;

      const lastYearBillingSummary = billingSummary.find(
        (summaryItem) => summaryItem.year === this.lastYear
      );
      if (!lastYearBillingSummary) return null;

      const pvp = lastYearBillingSummary.months.find(
        (monthItem) => monthItem.month === this.currentMonth
      ).pvp;

      return pvp;
    },
    currentMonthBillingIncrementPercentage() {
      if (
        (!this.currentMonthLastYearBilling &&
          this.currentMonthLastYearBilling !== 0) ||
        (!this.currentMonthCurrentYearBilling &&
          this.currentMonthCurrentYearBilling !== 0)
      )
        return null;

      if (this.currentMonthLastYearBilling === 0) return 100;

      const percentage =
        ((this.currentMonthCurrentYearBilling -
          this.currentMonthLastYearBilling) /
          this.currentMonthLastYearBilling) *
        100;
      return Math.round(percentage * 100) / 100;
    },
    currentMonthBillingIncrementPercentageIcon() {
      if (!this.currentMonthBillingIncrementPercentage) return null;
      return this.currentMonthBillingIncrementPercentage > 0
        ? "ArrowUpIcon"
        : "ArrowDownIcon";
    },
    lastYearBillingUntilCurrentMonth() {
      const billingSummary =
        this.summaryType === "CHECKIN"
          ? this.billingSummaryByCheckinDate
          : this.billingSummaryByBookingDate;

      if (!billingSummary?.length) return null;

      const lastYearBillingSummary = billingSummary.find(
        (summaryItem) => summaryItem.year === this.lastYear
      );
      if (!lastYearBillingSummary) return null;

      const pvp = lastYearBillingSummary.months
        .filter((monthItem) => monthItem.month <= this.currentMonth)
        .reduce((pvpAcc, monthItem) => pvpAcc + monthItem.pvp, 0);

      return pvp;
    },
    lastYearBillingUntilCurrentMonthText() {
      return this.lastYearBillingUntilCurrentMonth ||
        this.lastYearBillingUntilCurrentMonth === 0
        ? formatCurrency(this.lastYearBillingUntilCurrentMonth)
        : null;
    },
    lastYearProfitUntilCurrentMonth() {
      const billingSummary =
        this.summaryType === "CHECKIN"
          ? this.billingSummaryByCheckinDate
          : this.billingSummaryByBookingDate;

      if (!billingSummary?.length) return null;

      const lastYearBillingSummary = billingSummary.find(
        (summaryItem) => summaryItem.year === this.lastYear
      );
      if (!lastYearBillingSummary) return null;

      const profit = lastYearBillingSummary.months
        .filter((monthItem) => monthItem.month <= this.currentMonth)
        .reduce((profitAcc, monthItem) => profitAcc + monthItem.profit, 0);

      return profit;
    },
    lastYearProfitUntilCurrentMonthText() {
      return this.lastYearProfitUntilCurrentMonth ||
        this.lastYearProfitUntilCurrentMonth === 0
        ? formatCurrency(this.lastYearProfitUntilCurrentMonth)
        : null;
    },
    lastYearProfitPercentageUntilCurrentMonthText() {
      if (
        (!this.lastYearBillingUntilCurrentMonth &&
          this.lastYearBillingUntilCurrentMonth !== 0) ||
        (!this.lastYearProfitUntilCurrentMonth &&
          this.lastYearProfitUntilCurrentMonth !== 0)
      )
        return null;

      const percentage =
        (100 / this.lastYearBillingUntilCurrentMonth) *
        this.lastYearProfitUntilCurrentMonth;
      return `${percentage.toFixed(2)} %`;
    },
    currentYearBillingUntilCurrentMonth() {
      const billingSummary =
        this.summaryType === "CHECKIN"
          ? this.billingSummaryByCheckinDate
          : this.billingSummaryByBookingDate;

      if (!billingSummary?.length) return null;

      const currentYearBillingSummary = billingSummary.find(
        (summaryItem) => summaryItem.year === this.currentYear
      );
      if (!currentYearBillingSummary) return null;

      const pvp = currentYearBillingSummary.months
        .filter((monthItem) => monthItem.month <= this.currentMonth)
        .reduce((pvpAcc, monthItem) => pvpAcc + monthItem.pvp, 0);

      return pvp;
    },
    currentYearBillingUntilCurrentMonthText() {
      return this.currentYearBillingUntilCurrentMonth ||
        this.currentYearBillingUntilCurrentMonth === 0
        ? formatCurrency(this.currentYearBillingUntilCurrentMonth)
        : null;
    },
    currentYearProfitUntilCurrentMonth() {
      const billingSummary =
        this.summaryType === "CHECKIN"
          ? this.billingSummaryByCheckinDate
          : this.billingSummaryByBookingDate;

      if (!billingSummary?.length) return null;

      const currentYearBillingSummary = billingSummary.find(
        (summaryItem) => summaryItem.year === this.currentYear
      );
      if (!currentYearBillingSummary) return null;

      const profit = currentYearBillingSummary.months
        .filter((monthItem) => monthItem.month <= this.currentMonth)
        .reduce((profitAcc, monthItem) => profitAcc + monthItem.profit, 0);

      return profit;
    },
    currentYearProfitUntilCurrentMonthText() {
      return this.currentYearProfitUntilCurrentMonth ||
        this.currentYearProfitUntilCurrentMonth === 0
        ? formatCurrency(this.currentYearProfitUntilCurrentMonth)
        : null;
    },
    currentYearProfitPercentageUntilCurrentMonthText() {
      if (
        (!this.currentYearBillingUntilCurrentMonth &&
          this.currentYearBillingUntilCurrentMonth !== 0) ||
        (!this.currentYearProfitUntilCurrentMonth &&
          this.currentYearProfitUntilCurrentMonth !== 0)
      )
        return null;

      const percentage =
        (100 / this.currentYearBillingUntilCurrentMonth) *
        this.currentYearProfitUntilCurrentMonth;
      return `${percentage.toFixed(2)} %`;
    },
    currentYearProfitPercentageIncrementUntilCurrentMonth() {
      if (
        (!this.lastYearProfitUntilCurrentMonth &&
          this.lastYearProfitUntilCurrentMonth !== 0) ||
        (!this.currentYearProfitUntilCurrentMonth &&
          this.currentYearProfitUntilCurrentMonth !== 0)
      )
        return null;

      if (this.lastYearProfitUntilCurrentMonth === 0) return 100;

      const percentage =
        ((this.currentYearProfitUntilCurrentMonth -
          this.lastYearProfitUntilCurrentMonth) /
          this.lastYearProfitUntilCurrentMonth) *
        100;
      return Math.round(percentage * 100) / 100;
    },
    currentYearProfitPercentageIncrementUntilCurrentMonthIcon() {
      if (!this.currentYearProfitPercentageIncrementUntilCurrentMonth)
        return null;
      return this.currentYearProfitPercentageIncrementUntilCurrentMonth > 0
        ? "ArrowUpIcon"
        : "ArrowDownIcon";
    },
    currentYearBilling() {
      const billingSummary =
        this.summaryType === "CHECKIN"
          ? this.billingSummaryByCheckinDate
          : this.billingSummaryByBookingDate;

      if (!billingSummary?.length) return null;

      const currentYearBillingSummary = billingSummary.find(
        (summaryItem) => summaryItem.year === this.currentYear
      );
      if (!currentYearBillingSummary) return null;

      const pvp = currentYearBillingSummary.months.reduce(
        (pvpAcc, monthItem) => pvpAcc + monthItem.pvp,
        0
      );

      return pvp;
    },
    currentYearBillingText() {
      return this.currentYearBilling || this.currentYearBilling === 0
        ? formatCurrency(this.currentYearBilling)
        : null;
    },
    currentYearProfit() {
      const billingSummary =
        this.summaryType === "CHECKIN"
          ? this.billingSummaryByCheckinDate
          : this.billingSummaryByBookingDate;

      if (!billingSummary?.length) return null;

      const currentYearBillingSummary = billingSummary.find(
        (summaryItem) => summaryItem.year === this.currentYear
      );
      if (!currentYearBillingSummary) return null;

      const profit = currentYearBillingSummary.months.reduce(
        (profitAcc, monthItem) => profitAcc + monthItem.profit,
        0
      );

      return profit;
    },
    currentYearProfitText() {
      return this.currentYearProfit || this.currentYearProfit === 0
        ? formatCurrency(this.currentYearProfit)
        : null;
    },
    currentYearProfitPercentageText() {
      if (
        (!this.currentYearBilling && this.currentYearBilling !== 0) ||
        (!this.currentYearProfit && this.currentYearProfit !== 0)
      )
        return null;

      const percentage =
        (100 / this.currentYearBilling) * this.currentYearProfit;
      return `${percentage.toFixed(2)} %`;
    },
    lastYearBilling() {
      const billingSummary =
        this.summaryType === "CHECKIN"
          ? this.billingSummaryByCheckinDate
          : this.billingSummaryByBookingDate;

      if (!billingSummary?.length) return null;

      const lastYearBillingSummary = billingSummary.find(
        (summaryItem) => summaryItem.year === this.lastYear
      );
      if (!lastYearBillingSummary) return null;

      const pvp = lastYearBillingSummary.months.reduce(
        (pvpAcc, monthItem) => pvpAcc + monthItem.pvp,
        0
      );

      return pvp;
    },
    lastYearBillingText() {
      return this.lastYearBilling || this.lastYearBilling === 0
        ? formatCurrency(this.lastYearBilling)
        : null;
    },
    lastYearProfit() {
      const billingSummary =
        this.summaryType === "CHECKIN"
          ? this.billingSummaryByCheckinDate
          : this.billingSummaryByBookingDate;

      if (!billingSummary?.length) return null;

      const lastYearBillingSummary = billingSummary.find(
        (summaryItem) => summaryItem.year === this.lastYear
      );
      if (!lastYearBillingSummary) return null;

      const profit = lastYearBillingSummary.months.reduce(
        (profitAcc, monthItem) => profitAcc + monthItem.profit,
        0
      );

      return profit;
    },
    lastYearProfitText() {
      return this.lastYearProfit || this.lastYearProfit === 0
        ? formatCurrency(this.lastYearProfit)
        : null;
    },
    lastYearProfitPercentageText() {
      if (
        (!this.lastYearBilling && this.lastYearBilling !== 0) ||
        (!this.lastYearProfit && this.lastYearProfit !== 0)
      )
        return null;

      const percentage = (100 / this.lastYearBilling) * this.lastYearProfit;
      return `${percentage.toFixed(2)} %`;
    },
    currentYearProfitPercentageIncrement() {
      if (
        (!this.lastYearProfit && this.lastYearProfit !== 0) ||
        (!this.currentYearProfit && this.currentYearProfit !== 0)
      )
        return null;

      if (this.lastYearProfit === 0) return 100;

      const percentage =
        ((this.currentYearProfit - this.lastYearProfit) / this.lastYearProfit) *
        100;
      return Math.round(percentage * 100) / 100;
    },
    currentYearProfitPercentageIncrementIcon() {
      if (!this.currentYearProfitPercentageIncrement) return null;
      return this.currentYearProfitPercentageIncrement > 0
        ? "ArrowUpIcon"
        : "ArrowDownIcon";
    },
    currentYearBillingIncrementPercentage() {
      if (
        (!this.lastYearBilling && this.lastYearBilling !== 0) ||
        (!this.currentYearBilling && this.currentYearBilling !== 0)
      )
        return null;

      if (this.lastYearBilling === 0) return 100;

      const percentage =
        ((this.currentYearBilling - this.lastYearBilling) /
          this.lastYearBilling) *
        100;
      return Math.round(percentage * 100) / 100;
    },
    currentYearBillingIncrementPercentageIcon() {
      if (!this.currentYearBillingIncrementPercentage) return null;
      return this.currentYearBillingIncrementPercentage > 0
        ? "ArrowUpIcon"
        : "ArrowDownIcon";
    },
    nextYearBillingUntilCurrentMonth() {
      const billingSummary =
        this.summaryType === "CHECKIN"
          ? this.billingSummaryByCheckinDate
          : this.billingSummaryByBookingDate;

      if (!billingSummary?.length) return null;

      const nextYearBillingSummary = billingSummary.find(
        (summaryItem) => summaryItem.year === this.nextYear
      );
      if (!nextYearBillingSummary) return null;

      const pvp = nextYearBillingSummary.months
        .filter((monthItem) => monthItem.month <= this.currentMonth)
        .reduce((pvpAcc, monthItem) => pvpAcc + monthItem.pvp, 0);

      return pvp;
    },
    nextYearBillingUntilCurrentMonthText() {
      return this.nextYearBillingUntilCurrentMonth ||
        this.nextYearBillingUntilCurrentMonth === 0
        ? formatCurrency(this.nextYearBillingUntilCurrentMonth)
        : null;
    },
    nextYearProfitUntilCurrentMonth() {
      const billingSummary =
        this.summaryType === "CHECKIN"
          ? this.billingSummaryByCheckinDate
          : this.billingSummaryByBookingDate;

      if (!billingSummary?.length) return null;

      const nextYearBillingSummary = billingSummary.find(
        (summaryItem) => summaryItem.year === this.nextYear
      );
      if (!nextYearBillingSummary) return null;

      const profit = nextYearBillingSummary.months
        .filter((monthItem) => monthItem.month <= this.currentMonth)
        .reduce((profitAcc, monthItem) => profitAcc + monthItem.profit, 0);

      return profit;
    },
    nextYearProfitUntilCurrentMonthText() {
      return this.nextYearProfitUntilCurrentMonth ||
        this.nextYearProfitUntilCurrentMonth === 0
        ? formatCurrency(this.nextYearProfitUntilCurrentMonth)
        : null;
    },
    nextYearProfitPercentageUntilCurrentMonthText() {
      if (
        (!this.nextYearBillingUntilCurrentMonth &&
          this.nextYearBillingUntilCurrentMonth !== 0) ||
        (!this.nextYearProfitUntilCurrentMonth &&
          this.nextYearProfitUntilCurrentMonth !== 0)
      )
        return null;

      const percentage =
        (100 / this.nextYearBillingUntilCurrentMonth) *
        this.nextYearProfitUntilCurrentMonth;
      return `${percentage.toFixed(2)} %`;
    },
    nextYearBilling() {
      const billingSummary =
        this.summaryType === "CHECKIN"
          ? this.billingSummaryByCheckinDate
          : this.billingSummaryByBookingDate;

      if (!billingSummary?.length) return null;

      const nextYearBillingSummary = billingSummary.find(
        (summaryItem) => summaryItem.year === this.nextYear
      );
      if (!nextYearBillingSummary) return null;

      const pvp = nextYearBillingSummary.months.reduce(
        (pvpAcc, monthItem) => pvpAcc + monthItem.pvp,
        0
      );

      return pvp;
    },
    nextYearBillingText() {
      return this.nextYearBilling || this.nextYearBilling === 0
        ? formatCurrency(this.nextYearBilling)
        : null;
    },
    nextYearProfit() {
      const billingSummary =
        this.summaryType === "CHECKIN"
          ? this.billingSummaryByCheckinDate
          : this.billingSummaryByBookingDate;

      if (!billingSummary?.length) return null;

      const nextYearBillingSummary = billingSummary.find(
        (summaryItem) => summaryItem.year === this.nextYear
      );
      if (!nextYearBillingSummary) return null;

      const profit = nextYearBillingSummary.months.reduce(
        (profitAcc, monthItem) => profitAcc + monthItem.profit,
        0
      );

      return profit;
    },
    nextYearProfitText() {
      return this.nextYearProfit || this.nextYearProfit === 0
        ? formatCurrency(this.nextYearProfit)
        : null;
    },
    nextYearProfitPercentageText() {
      if (
        (!this.nextYearBilling && this.nextYearBilling !== 0) ||
        (!this.nextYearProfit && this.nextYearProfit !== 0)
      )
        return null;

      const percentage = (100 / this.nextYearBilling) * this.nextYearProfit;
      return `${percentage.toFixed(2)} %`;
    },
  },
  created() {
    this.fetchBillingSummaries();
  },
  methods: {
    fetchBillingSummaries() {
      this.loading = true;
      const promises = [];

      if (!this.billingSummaryByBookingDate?.length) {
        promises.push(
          this.$store.dispatch("stats/fetchBillingSummaryByBookingDate")
        );
      }

      if (!this.billingSummaryByCheckinDate?.length) {
        promises.push(
          this.$store.dispatch("stats/fetchBillingSummaryByCheckinDate")
        );
      }

      Promise.all(promises)
        .catch(() =>
          notifyError(
            this.$t("errors.fetchBookings.title"),
            this.$t("errors.fetchBookings.description")
          )
        )
        .finally(() => {
          this.loading = false;
        });
    },
    getBillingSummaryByTypeAndYear(summaryType, year) {
      const billingSummary =
        this.summaryType === "CHECKIN"
          ? this.billingSummaryByCheckinDate
          : this.billingSummaryByBookingDate;

      if (!billingSummary?.length) return null;

      const yearBillingSummary = billingSummary.find(
        (summaryItem) => summaryItem.year === year
      );
      if (!yearBillingSummary) return null;

      const data = yearBillingSummary.months
        .sort((a, b) => {
          if (a.month > b.month) return 1;
          if (b.month > a.month) return -1;
          return 0;
        })
        .map((monthItem) => monthItem.pvp / 100);

      return {
        year,
        data,
      };
    },
    getSeriesItem(summaryData, color) {
      if (!summaryData) return null;
      return {
        name: summaryData.year.toString(),
        type: "line",
        areaStyle: { color },
        color,
        showSymbol: false,
        smooth: 0.3,
        lineStyle: { width: 2 },
        data: summaryData.data,
      };
    },
  },
};
</script>
