<template>
  <div v-if="isAdmin">
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-card-header class="p-0">
            <b-card-title> Tipus d'estadístiques </b-card-title>
            <b-form-radio-group
              v-model="summaryType"
              :options="summaryTypeOptions"
              value-field="value"
              text-field="text"
              disabled-field="disabled"
            />
          </b-card-header>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" lg="9">
        <billing-summary-card :summary-type="summaryType" />
      </b-col>
      <b-col cols="12" lg="3">
        <monthly-billing-forecast-card
          v-if="summaryType === 'BOOKING'"
          :summary-type="summaryType"
        />
        <advance-booking-time-card :summary-type="summaryType" />
        <direct-bookings-card :summary-type="summaryType" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormRadioGroup,
} from "bootstrap-vue";
import BillingSummaryCard from "@/views/stats/components/BillingSummaryCard.vue";
import AdvanceBookingTimeCard from "@/views/stats/components/AdvanceBookingTimeCard.vue";
import DirectBookingsCard from "@/views/stats/components/DirectBookingsCard.vue";
import MonthlyBillingForecastCard from "@/views/stats/components/MonthlyBillingForecastCard.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormRadioGroup,
    BillingSummaryCard,
    AdvanceBookingTimeCard,
    DirectBookingsCard,
    MonthlyBillingForecastCard,
  },
  data() {
    return {
      summaryType: "BOOKING",
      summaryTypeOptions: [
        { text: "Per data de reserva", value: "BOOKING", disabled: false },
        { text: "Per data d'entrada", value: "CHECKIN", disabled: false },
      ],
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters["auth/isAdmin"];
    },
    isManager() {
      return this.$store.getters["auth/isManager"];
    },
  },
};
</script>
